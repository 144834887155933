/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { PlcSound } from '@components/models/sound.models';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DeviceAudio, deviceFeatureKey, DeviceState } from './device.reducer';

export const selectDeviceState =
	createFeatureSelector<DeviceState>(deviceFeatureKey);

export class DeviceSelectors {
	public static isPhone = createSelector(
		selectDeviceState,
		(state: DeviceState) => state.isPhone,
	);

	public static audio = (id: string) =>
		createSelector(selectDeviceState, ({ audios }: DeviceState) =>
			audios.find(
				(audio: DeviceAudio<PlcSound | string>) => audio.id === id,
			),
		);

	public static audios = createSelector(
		selectDeviceState,
		({ audios }: DeviceState) => audios,
	);

	public static volume = createSelector(
		selectDeviceState,
		({ volume }: DeviceState) => volume,
	);
}
