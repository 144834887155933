import { PlcSound } from '@components/models/sound.models';
import { AudioPlayOptions } from '@core/services/native/audio.feature';
import { createAction, props } from '@ngrx/store';

import { DeviceAudio } from './device.reducer';

export const loadDevices = createAction('[Device] Load Devices');

export interface DevicePreloadAudiosAction {
	audios: DeviceAudio<PlcSound | string>[];
}

export interface DevicePlayAudioAction {
	id: string;
	options?: AudioPlayOptions;
}

export interface DeviceStopAudioAction {
	id: string;
}

export interface DeviceSetIsPhoneAction {
	isPhone: boolean;
}

export interface DeviceSetVolumeAction {
	volume: number;
}

export class DeviceActions {
	public static preloadAudios = createAction(
		'[Device] Preload Audios',
		props<DevicePreloadAudiosAction>(),
	);

	public static playAudio = createAction(
		'[Device] Play Audio',
		props<DevicePlayAudioAction>(),
	);

	public static stopAudio = createAction(
		'[Device] Stop Audio',
		props<DeviceStopAudioAction>(),
	);

	public static stopAllAudios = createAction('[Device] Stop All Audios');

	public static setIsMobile = createAction(
		'[Device] Set Is Mobile',
		props<DeviceSetIsPhoneAction>(),
	);

	public static setVolume = createAction(
		'[Device] Set Volume',
		props<DeviceSetVolumeAction>(),
	);

	public static setInitialVolume = createAction(
		'[Device] Set Initial Volume',
	);
}
