import { Injectable } from '@angular/core';
import { PlcSound } from '@components/models/sound.models';
import {
	AudioFeature,
	AudioPlayOptions,
} from '@core/services/native/audio.feature';
import { PlcPlatform } from '@core/utils/platform.util';
import { Store } from '@ngrx/store';

import { DeviceActions } from './device.actions';
import { DeviceAudio } from './device.reducer';
import { DeviceSelectors } from './device.selectors';

@Injectable()
export class DeviceFacade {
	public isPhone$ = this.store.select(DeviceSelectors.isPhone);
	public volume$ = this.store.select(DeviceSelectors.volume);

	constructor(
		private store: Store,
		private window: Window,
		private platform: PlcPlatform,
		private audioFeature: AudioFeature,
	) {
		this.store.dispatch(
			DeviceActions.setIsMobile({ isPhone: this.platform.isPhone }),
		);

		this.window.onresize = (): void => {
			this.store.dispatch(
				DeviceActions.setIsMobile({ isPhone: this.platform.isPhone }),
			);
		};

		this.volume$.subscribe((volume: number) =>
			this.audioFeature.changeVolume(volume),
		);
	}

	public preloadAudios(...audios: DeviceAudio<PlcSound | string>[]): void {
		this.store.dispatch(DeviceActions.preloadAudios({ audios }));
	}

	public playAudio(id: string, options?: AudioPlayOptions): void {
		this.store.dispatch(DeviceActions.playAudio({ id, options }));
	}

	public stopAudio(id: string): void {
		this.store.dispatch(DeviceActions.stopAudio({ id }));
	}

	public stopAllAudios(): void {
		this.store.dispatch(DeviceActions.stopAllAudios());
	}
}
