import { PreferencesConstants } from '@api/models/preferences.constants';
import { PlcSound } from '@components/models/sound.models';
import { Action, createReducer, on } from '@ngrx/store';

import {
	DeviceActions,
	DevicePreloadAudiosAction,
	DeviceSetIsPhoneAction,
	DeviceSetVolumeAction,
} from './device.actions';

export const deviceFeatureKey = 'device';

export class DeviceAudio<T> {
	public id: T;
	public src: string;
}

export interface DeviceState {
	audios: DeviceAudio<PlcSound | string>[];
	isPhone: boolean;
	volume: number;
}

export const initialState: DeviceState = {
	audios: [],
	isPhone: false,
	volume: PreferencesConstants.defaultVolume,
};

const preloadAudiosFunc = (
	state: DeviceState,
	action: DevicePreloadAudiosAction,
): DeviceState => ({
	...state,
	audios: state.audios.concat(
		action.audios.filter(
			(newAudio: DeviceAudio<PlcSound | string>) =>
				!state.audios.find(
					(existingAudio: DeviceAudio<PlcSound | string>) =>
						existingAudio.id === newAudio.id,
				),
		),
	),
});

const setIsPhoneFunc = (
	state: DeviceState,
	action: DeviceSetIsPhoneAction,
): DeviceState => ({
	...state,
	isPhone: action.isPhone,
});

const setVolumeFunc = (
	state: DeviceState,
	action: DeviceSetVolumeAction,
): DeviceState => ({
	...state,
	volume: action.volume,
});

export const reducer = createReducer(
	initialState,

	on(DeviceActions.preloadAudios, preloadAudiosFunc),
	on(DeviceActions.setIsMobile, setIsPhoneFunc),
	on(DeviceActions.setVolume, setVolumeFunc),
);

export function devicesReducer(
	state = initialState,
	action: Action,
): DeviceState {
	return reducer(state, action);
}
